/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.marketplace-swiper .swiper-button-next,
.marketplace-swiper .swiper-button-prev {
   top: 24px;
}

.marquee {
   width: 100%;
   overflow: hidden;
   position: relative;
   height: 22px;
   line-height: 2;
}

.marquee div {
   display: block;
   width: 200%;
   height: 30px;
   position: absolute;
   overflow: hidden;
   animation: marquee 35s linear infinite;
}

.marquee span {
   float: left;
   width: 50%;
}

@keyframes marquee {
   0% {
      left: 0;
   }

   100% {
      left: -100%;
   }
}

.csm-error {
   color: $red;
   margin-top: 0.5rem;
   font-size: 0.875rem;
}

.csm-btn-reset {
   background: none;
   color: inherit;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: inherit;
}

.csm-underline {
   text-decoration: underline;
}

.csm-bold {
   font-weight: bold;
}
.csm-semibold {
   font-weight: 500;
}

.csm-table-row-break {
   white-space: break-spaces !important;
   word-break: inherit;
   max-width: 350px;

   @media (min-width: '860px') {
      white-space: break-spaces !important;
      word-break: break-all;
      max-width: 500px;
   }
}

.csm-ml-auto {
   margin-left: auto;
}

// Custom Timeline
ul.timeline-3 {
   list-style-type: none;
   position: relative;
}
ul.timeline-3:before {
   content: ' ';
   background: #d4d9df;
   display: inline-block;
   position: absolute;
   left: 29px;
   width: 2px;
   height: 100%;
   z-index: 400;
}
ul.timeline-3 > li {
   margin: 20px 0;
   padding-left: 20px;
}
ul.timeline-3 > li:before {
   content: ' ';
   background: white;
   display: inline-block;
   position: absolute;
   border-radius: 50%;
   border: 3px solid #22c0e8;
   left: 20px;
   width: 20px;
   height: 20px;
   z-index: 400;
}

.csm-blue-text {
   color: #3577f1;
}

.csm-blue-bg {
   background: #3577f1;
}

.csm-link-reset {
   text-decoration: none; /* no underline */
}

.csm-link-reset:hover {
   color: inherit;
   background: transparent;
   text-decoration: none;
}

.csm-button-reset {
   background: none;
   color: inherit;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: inherit;
}

.csm-violet-badge {
   color: #7935f1;
   background-color: rgba(112, 53, 241, 0.1);
}

.csm-cursor-not-allowed {
   cursor: not-allowed;
}

.csm-btn-secondary-active {
   color: #fff !important;
   background-color: #3577f1 !important;
   border-color: transparent;
}

.csm-btn-soft-primary-active {
   color: $white !important;
   background-color: $primary !important;
   border-color: transparent;
}

// Pagination
.rc-pagination li.title {
   display: none !important;
}
.rc-pagination-item {
   font-weight: 400 !important;
   border-color: #e9ebec !important;
}


.csm-pagination-active, .rc-pagination-item-active, .rc-pagination-item:focus, .rc-pagination-item:hover, .rc-pagination-item-link:hover {
   color: #003632 !important;
   background-color: #eff2f7 !important;
   border-color: #e9ebec !important;
   font-weight: 400 !important;
}

.rc-pagination-prev .rc-pagination-item-link, .rc-pagination-next .rc-pagination-item-link {
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
}